import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import VisualisationItem from './VisualisationItem'
import { isEqual } from 'lodash'
import { defaultBlank } from '@images/visualisation/blank'
import { Image } from '@components/shared'
// eslint-disable-next-line import/extensions
import PrintWhite from '@images/print-white.svg'
// eslint-disable-next-line import/extensions
import PrintBlack from '@images/print-black.svg'
import { isTouch } from '@utils/isTouch'

class Visualisation extends Component {
  state = {
    blank: null
  }

  componentDidUpdate(prevProps) {

    const {
      blankImages,
      match: {params: { type }}
    } = this.props

    if(!isEqual(blankImages, prevProps.blankImages)) {
      this.selectBlank()
    }

    if(type !== prevProps.match.params.type) {
      this.selectBlank()
    }
  }

  selectBlank = () => {
    const {
      match: {params: { type }}
    } = this.props
    const { blankImages } = this.props

    blankImages.forEach((el) => {
      if (el.id === type) {
        this.setState({ blank: el.blank })
      }
    })
  }

  printHandler = () => {
    window.print()
  }

  render() {
    const { top, left, right, door } = this.props.match.params
    const { blank } = this.state
    return (
      <div className='visualisation__variant__container'>
        <Image className='blank' src={blank ? blank : defaultBlank} alt='blank'/>
        <div className='visualisation__variant--left'>
          <VisualisationItem type='left' id={left} />
        </div>
        <div className='visualisation__variant--top'>
          <VisualisationItem type='top' id={top} />
        </div>
        <div className='visualisation__variant--right'>
          <VisualisationItem type='right' id={right} />
        </div>
        <div className='visualisation__variant--door'>
          <VisualisationItem type='door' id={door} />
        </div>
        {!isTouch() && (
          <Fragment>
            <button class='print-icon white' onClick={this.printHandler}>
              <img src={PrintWhite} alt=""/>
            </button>
            <button class='print-icon black' onClick={this.printHandler}>
              <img src={PrintBlack} alt=""/>
            </button>
          </Fragment>
        )}
      </div>
    )
  }
}
 
export default withRouter(Visualisation)

