import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const AfterSumbissionModal = (props) => {
  const { open, toggle, isSuccessForm } = props
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>{isSuccessForm ? 'Sukces' : 'Błąd'}</ModalHeader>
      <ModalBody>
        {
          isSuccessForm ?
            'Twoja specyfikacja została wysłana'
            :
            'Coś poszło nie tak'
        }
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>OK</Button>
      </ModalFooter>
    </Modal>
  )
}
 
export default AfterSumbissionModal