import React from 'react'
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom'
import { Home, Error } from '@scenes'

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/404" component={Error} />
      <Route exact path="/" component={Home} />
      <Route exact path="/:type" component={Home} />
      <Route exact path="/:type/:top/:left/:right/:door" component={Home} />
    </Switch>
  </BrowserRouter>
)

export default Router