import React, { Component, Fragment } from 'react'
import { Row, Col } from 'reactstrap'
import { SelectedConfigurationItem } from '@components/shared'

class SelectedConfiguration extends Component {
  render() { 
    return (
      <Fragment>
        <h4 className='selected__configuration__title'>Wybrana przez Ciebie konfiguracja</h4>
        <Row>
          <Col sm='3'>
            <h5 className='selected__item__title'>Lewa strona</h5>
            <SelectedConfigurationItem type='left' />
          </Col>
          <Col sm='3'>
            <h5 className='selected__item__title'>Góra</h5>
            <SelectedConfigurationItem type='top' />
          </Col>
          <Col sm='3'>
            <h5 className='selected__item__title'>Prawa strona</h5>
            <SelectedConfigurationItem type='right' />
          </Col>
          <Col sm='3'>
            <h5 className='selected__item__title'>Drzwi</h5>
            <SelectedConfigurationItem type='door' />
          </Col>
        </Row>
      </Fragment>
    )
  }
}
 
export default SelectedConfiguration
