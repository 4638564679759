import { connect } from 'react-redux'
import ColorPicker from '../components/ColorPicker'

const mapStateToProps = (state) => {
  const {
    models: { modelsList: {
      door,
      left,
      right,
      top
    } }
  } = state

  return {
    door,
    left,
    right,
    top
  }
}

export default connect(mapStateToProps)(ColorPicker)