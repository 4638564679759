import React, { Component } from 'react'
import { Card } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class SelectedConfigurationItem extends Component {
  render() {
    const {
      type,
      match: { params },
      modelsList
    } = this.props
    const id = params[type]
    let selectedElement
    modelsList[type].forEach(element => {
      if (element.id === id) {
        selectedElement = element
      }
    })

    return (
      <Card className='selected__element'>
        <div className='selected__element--inner'>
          <div className='selected__color' style={selectedElement && {backgroundColor: selectedElement.pickerColor}} />
          <div className='selected__details'>
            {/* <p>Cena: {selectedElement ? `od ${ selectedElement.price }` : 'nie wybrano'}</p> */}
            <p>ID: {selectedElement && selectedElement.id}</p>
          </div>         
        </div>
        <div>

        </div>
      </Card>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    models: { modelsList }
  } = state

  return {
    modelsList
  }
}
 
export default withRouter(connect(mapStateToProps)(SelectedConfigurationItem))
