import React, { Component } from 'react'
import SingleVariant from './SingleVariant'

class Picker extends Component {
  render() { 
    const { colorVariants, type, activeItem, modelType } = this.props
    return (
      <div className='variant__list'>
        {
          colorVariants && colorVariants.map((variant, index) =>
            <SingleVariant
              key={index}
              variant={variant}
              type={type}
              activeItem={activeItem}
              modelType={modelType}
            />
          )
        }
      </div>
    )
  }
}
 
export default Picker
