import React, { Component, Fragment } from 'react'
import { Repository } from '@utils/repository'
import { withRouter } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { isTouch } from '@utils/isTouch'
import { scrollTo } from '@utils/scrollTo'
import { Image } from '@components/shared'

class Models extends Component {
  state = {
    models: [],
    activeModelKey: null,
    hoveredModel: null,
    redirect: false,
    error: false,
    onLoadClass: null,
    secondLevelVisible: false
  }
  async componentDidMount() {
    const { match: { params: { type } } } = this.props
    const response = await Repository.getVariantList()
    this.handleBlankImages(response)

    this.setState({ models: response })

    this.handleClassOnload()

    const visualisation = document.querySelector('.visualisation__variant__container')
    if (visualisation && type) {
      scrollTo(visualisation, 150)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const secondLevelVisible = document.querySelector('.model__list.level-1 > .active')

    if (secondLevelVisible && !this.state.secondLevelVisible) {
      this.setState({ secondLevelVisible: true })
    } else if(!secondLevelVisible && this.state.secondLevelVisible) {
      this.setState({ secondLevelVisible: false })
    }
  }

  handleClassOnload = () => {
    const { match: { params: { type } } } = this.props
    if (type) {
      this.setState({ onLoadClass: type[0] })
    }
  }

  handleBlankImages = (data) => {
    let arr = []
    data.forEach((el => {
      const { variants } = el
      variants && variants.forEach(element => {
        const { id, blank } = element
        arr.push({ id, blank })
      })
    }))

    this.props.getBlankImages(arr)
  }

  modelClick = (modelKey) => {
    const { activeModelKey } = this.state

    this.setState({ onLoadClass: null })
    if(activeModelKey === modelKey) {
      this.setState({ activeModelKey: null })
    } else {
      this.setState({ activeModelKey: modelKey })

      setTimeout(() => {
        const variants = document.querySelector('.model__list.level-1 > .active > .model__list-wrapper.level-2')
        if(isTouch() && variants) {
          scrollTo(variants, 120)
        }
      }, 50)
    }
  }

  variantClickHandler = async (id) => {
    const { loadModelsList, modelsListFailed } = this.props

    try {
      const response = await Repository.getModelsList(id)
      if(response) {
        loadModelsList(response)
        this.setState({ redirect: id })
      } else {
        this.setState({ error: true })
        modelsListFailed()
      }
    } catch(err) {
      this.setState({ error: true })
      modelsListFailed()
    }
    
    const visualisation = document.querySelector('.visualisation__variant__container')
    if (visualisation) {
      scrollTo(visualisation, 150)
    }
  }

  render() {
    const { models, activeModelKey, redirect, error, onLoadClass, secondLevelVisible } = this.state
    const { match: { params: { type } } } = this.props

    return (
      <Fragment>
        {redirect && <Redirect to={'/' + redirect} />}
        {error && <Redirect to='/404' />}
        <div className={`model__list-wrapper level-1 ${ !secondLevelVisible ? 'secondLevelHidden' : '' }`}>
          
          <ul className='container model__list level-1'>
            <h2>Wybierz model</h2>
            {models.map((model, modelKey) => (
              <li key={modelKey} className={
                (activeModelKey === modelKey) ||
                ((modelKey + 1).toString() === onLoadClass) ?'active' : ''}>
                <div className='image__wrapper' onClick={() => this.modelClick(modelKey)}>
                  <Image className='image__basic' src={model.img} alt='' />
                  <Image className='image__active' src={model.img_active} alt='' />
                </div>
                <div className='model__list-wrapper level-2'>

                  <h2>Wybierz wariant</h2>
                  <ul className='container model__list level-2'>
                    {model.variants.map((variant, variantKey) => (
                      <li key={variantKey}>
                        <div className={`image__wrapper ${ variant.id === type ? 'active' : '' }`} onClick={() => this.variantClickHandler(variant.id)}>
                          <Image className='image__basic' src={variant.img} alt='' />
                          <Image className='image__active' src={variant.img_active} alt='' />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Fragment>
    )
  }
}
 
export default withRouter(Models)