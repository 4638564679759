import { connect } from 'react-redux'
import Visualisation from '../components/Visualisation'

const mapStateToProps = (state) => {
  const {
    models: { blankImages }
  } = state

  return {
    blankImages
  }
}

export default connect(mapStateToProps)(Visualisation)