import React, { Component } from 'react'
import { Form as BootstrapForm, Input as BootstrapInput, Button, CustomInput, FormGroup, Spinner } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import { Input } from './Input'
import { formValidationSchema } from './formValidationSchema'
import { submit } from './submit'
import AfterSumbissionModal from './AfterSubmissionModal'

class Form extends Component {
  state = {
    email: '',
    companyName: '',
    phone: '',
    contactPerson: '',
    amount: '',
    version: '',
    message: '',
    rodo: false,
    marketing: false,
    open: false,
    isSuccessForm: false
  }

  handleSubmit = async (values, resetForm, setSubmitting) => {
    const {
      match: {
        params: {
          door,
          left,
          right,
          top
        }
      }
    } = this.props
    if (door && left && right && top) {
      const formData = {
        ...values,
        ...{configuration: window.location.href},
        ...{topSide: top},
        ...{leftSide: left},
        ...{rightSide: right},
        ...{door: door}
      }
      await submit(formData)
      setSubmitting(false)
      resetForm()
      this.setState({ isSuccessForm: true })
      this.toggle()
    } else {
      setSubmitting(false)
      this.setState({ isSuccessForm: false })
      this.toggle()
    }
  }

  toggle = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    return (
      <div className='form__wrapper'>
        <span className='form__wrapper--title'>Chcę dostać ofertę na wskazaną specyfikację</span>
        <Formik
          enableReinitialize={true}
          initialValues = {this.state}
          validationSchema={formValidationSchema}
          onSubmit={(values, {resetForm, setSubmitting}) => this.handleSubmit(values, resetForm, setSubmitting)}
        >
          {
            props => (
              <BootstrapForm onSubmit={props.handleSubmit}>
                <Input 
                  name='email'
                  label='Adres email' 
                  onChange={props.handleChange('email')}
                  onBlur={props.handleBlur('email')}
                  error={props.errors.email}
                  touched={props.touched.email}
                  value={props.values.email}
                />
                <Input 
                  name='companyName'
                  label='Nazwa firmy' 
                  onChange={props.handleChange('companyName')}
                  onBlur={props.handleBlur('companyName')}
                  error={props.errors.companyName}
                  touched={props.touched.companyName}
                  value={props.values.companyName}
                />
                <Input 
                  name='phone'
                  label='Telefon' 
                  onChange={props.handleChange('phone')}
                  onBlur={props.handleBlur('phone')}
                  error={props.errors.phone}
                  touched={props.touched.phone}
                  value={props.values.phone}
                />
                <Input 
                  name='contactPerson'
                  label='Osoba kontaktowa' 
                  onChange={props.handleChange('contactPerson')}
                  onBlur={props.handleBlur('contactPerson')}
                  error={props.errors.contactPerson}
                  touched={props.touched.contactPerson}
                  value={props.values.contactPerson}
                />
                <Input 
                  name='amount'
                  label='Orientacyjna ilość sztuk' 
                  onChange={props.handleChange('amount')}
                  onBlur={props.handleBlur('amount')}
                  error={props.errors.amount}
                  touched={props.touched.amount}
                  value={props.values.amount}
                />
                <Input 
                  name='version'
                  label='Jedna wersja / Kilka wersji' 
                  onChange={props.handleChange('version')}
                  onBlur={props.handleBlur('version')}
                  error={props.errors.version}
                  touched={props.touched.version}
                  value={props.values.version}
                />
                <FormGroup>
                  <BootstrapInput 
                    name='message'
                    type='textarea' 
                    placeholder='Wiadomość' 
                    onChange={props.handleChange('message')}
                    onBlur={props.handleBlur('message')}
                    invalid={props.errors.message && props.touched.message ? true : false}
                    value={props.values.message}
                  />
                </FormGroup>
                <div className='checkbox__wrapper'>
                  <CustomInput
                    id='rodo'
                    name='rodo'
                    type='checkbox'
                    onChange={props.handleChange('rodo')}
                    value={props.values.rodo}
                  />
                  <p>
                    <strong>Zgoda na udostępnianie danych osobowych *</strong><br/>
                    Niniejszym oświadczam, że zgodnie z art. 23 ust. 1 pkt 1 ustawy z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz.U. z 2002 r. Nr 101, poz. 926) wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych w celach marketingowych w tym celem otrzymywania informacji handlowych drogą elektroniczną pochodzących od Staniec Kamiński SC na potrzeby promocji jej usług. Udostępniane dane chronione w pełni zgodnie z Ustawą o ochronie danych osobowych. Staniec Kamiński SC jest administratorem bazy danych osobowych. Udostępniający ma prawo do wgIądu, zmiany i usunięcia danych osobowych z bazy Staniec Kamiński SC Udostępnianie danych jest dobrowolne. 
                  </p>
                </div>
                {props.touched.rodo && props.errors.rodo && <div className='error__field'><p className='error__text'>{props.errors.rodo}</p></div>}
                <div className='checkbox__wrapper'>
                  <CustomInput
                    id='marketing'
                    name='marketing'
                    type='checkbox'
                    onChange={props.handleChange('marketing')}
                    value={props.values.marketing}
                  />
                  <p>
                    <strong>Polityka Prywatności *</strong><br/>
                    Zapoznałem się z polityką prywatności
                  </p>
                </div>
                {props.touched.marketing && props.errors.marketing && <div className='error__field'><p className='error__text'>{props.errors.marketing}</p></div>}
                <Button type='submit' disabled={props.isSubmitting} className='submit__button' block color='success'>
                  <p>WYSYŁAM</p>
                  {props.isSubmitting && <Spinner color="light" />}
                </Button>
              </BootstrapForm>
            )
          }          
        </Formik>
        <AfterSumbissionModal
          open={this.state.open}
          toggle={this.toggle}
          isSuccessForm={this.state.isSuccessForm}
        />
      </div>
    )
  }
}
 
export default withRouter(Form)

