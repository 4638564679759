import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '@components/home/components/models'
import Home from '../Home'

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...actions
  },
  dispatch
)

export default connect(null, mapDispatchToProps)(Home)