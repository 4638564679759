import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class Error extends Component {
  render() {
    return (
      <div className='page404'>
        <Modal className='error__modal' isOpen centered>
          <ModalHeader>Coś poszło nie tak</ModalHeader>
          <ModalBody>
            <p>Wybrana przez Ciebie konfiguracja nie istnieje.</p>
          </ModalBody>
          <ModalFooter>
            <Link to='/'>
              <Button color='success'>Powrót</Button>
            </Link>
          </ModalFooter>
        </Modal>
      </div>
      
    )
  }
}

export default Error