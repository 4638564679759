import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBEKhFHVLeK_Pn3vCNHhHgVQiLOiEQENeo",
  authDomain: "portale-drzwiowe-9810c.firebaseapp.com",
  databaseURL: "https://portale-drzwiowe-9810c.firebaseio.com",
  projectId: "portale-drzwiowe-9810c",
  storageBucket: "portale-drzwiowe-9810c.appspot.com",
  messagingSenderId: "880671313292",
  appId: "1:880671313292:web:5c93025df0d85317c25aa9"
}

firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()
