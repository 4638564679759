import React, { Component } from 'react'
import SwitchComponent from "react-switch"

class Switch extends Component {
  state = {
    checked: false
  }

  componentDidMount() {
    const theme = window.localStorage.getItem('theme')
    if(theme === 'light') {
      this.setState({ checked: true })
    } 
  }

  handleChange = () => {
    const { checked } = this.state
    if(checked) {
      window.localStorage.setItem('theme', 'dark')
      document.getElementById('main').className='dark'
    } else {
      window.localStorage.setItem('theme', 'light')
      document.getElementById('main').className='light'
    }
    
    this.setState({ checked: !this.state.checked })
  }

  render() {
    const { checked } = this.state
    return (
      <SwitchComponent
        checked={checked}
        uncheckedIcon={false}
        checkedIcon={false}
        width={40}
        height={20}
        onColor='#35B25A'
        onChange={this.handleChange}
      />   
    )
  }
}
 
export default Switch