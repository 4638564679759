import * as Yup from 'yup'

export const formValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Niepoprawny adres email')
    .required('Pole obowiązkowe'),
  companyName: Yup.string()
    .min(2, 'Za krótkie!')
    .max(50, 'Za długie!')
    .required('Pole obowiązkowe'),
  phone: Yup.string()
    .required('Pole obowiązkowe')
    .matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/, 'Nieprawidłowy numer telefonu'),
  contactPerson: Yup.string()
    .min(2, 'Za krótkie!')
    .max(50, 'Za długie!')
    .required('Pole obowiązkowe'),
  amount: Yup.string()
    .max(50, 'Za długie!')
    .required('Pole obowiązkowe'),
  version: Yup.string()
    .max(50, 'Za długie!')
    .required('Pole obowiązkowe'),
  message: Yup.string()
    .required('Pole obowiązkowe'),
  rodo: Yup.boolean()
    .oneOf([true], 'Pole obowiązkowe'),
  marketing: Yup.boolean()
    .oneOf([true], 'Pole obowiązkowe')
})