import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Image } from '@components/shared'

class VisualisationItem extends Component {
  render () {
    const { type, id, modelsList } = this.props
    let img = null
    modelsList[type].forEach(element => {
      if (id === element.id) {
        img = element.image
      }
    })
    return (
      <Fragment>
        {
          img && <Image className='visualisation__item' src={img} alt={id} />
        }
      </Fragment>
    )
  }  
}

const mapStateToProps = (state) => {
  const { models: { modelsList } } = state
  return {
    modelsList
  }
}

export default connect(mapStateToProps, null)(VisualisationItem)
