import { db } from './firebase'

export class Repository {
  static async getModelsList(type) {
    const docRef = db.collection('models').doc(type)
    const response = await docRef.get()
    return response.data()
  }

  static async getVariantList() {
    const docRef = db.collection('variants')
    const response = await docRef.get()
    return response.docs.map(doc => doc.data())
  }

  static async saveModel(name, data) {
    const docRef = db.collection('models').doc(name)

    await docRef.set(data)
  }
}