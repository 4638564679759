import React, { Component, Fragment } from 'react'
import { Container } from 'reactstrap'
import { Header } from '@components/shared/header'
import { Loader } from '@components/loader'
import { PortalChooser } from './components/portalChooser'
import { SelectedConfiguration } from './components/selectedConfiguration'
import { Form } from './components/form'
import { Footer } from './components/footer'
import { Repository } from '@utils/repository'
import { withRouter, Redirect } from 'react-router-dom'
import { Models } from '@components/home/components/models'

class Home extends Component {
  state = {
    loading: true,
    error: false,
    theme: 'dark',
    mobile: false
  }
  async componentDidMount() {
    this.getLayout()
    await this.fetchData()
    this.checkMobile()
    window.addEventListener('resize', this.checkMobile)
  }

  checkMobile = () => {
    const width = window.innerWidth
    if (width > 768) {
      this.setState({ mobile: false })
    } else {
      this.setState({ mobile: true })
    }
  }

  fetchData = async () => {
    const { match: { params: { type } } } = this.props
    if(type) {
      await this.loadData(type)
    } else {
      this.fakePreload()
    }
  }

  loadData = async (type) => {
    try {
      const response = await Repository.getModelsList(type)
      if(response) {
        this.handleSuccess(response)
      } else {
        this.handleError()
      }
    } catch(err) {
      this.handleError()
    }
  }

  handleSuccess = (response) => {
    const { loadModelsList } = this.props
    loadModelsList(response)
    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 2000)
  }

  handleError = () => {
    setTimeout(() => {
      this.setState({
        error: true,
        loading: false
      })
    }, 2000)
  }

  fakePreload = () => {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 2000)
  }

  getLayout = () => {
    const theme = window.localStorage.getItem('theme')
    if (theme) {
      this.setState({ theme })
    }
  }

  render() {
    const { loading, error, theme, mobile } = this.state
    return (
      <main id='main' className={theme}>
        {loading && <Loader />}
        {error && <Redirect to='/404' />}
        {mobile ? (
          <Fragment>
            <div className='basic__layout section-1'>
              <Container>
                <Header />
                <Models />
                <PortalChooser />
              </Container>
            </div>
            <div className='break'><div></div></div>
          </Fragment>
        ) : (
          <Fragment>
            <div className='basic__layout section-1'>
              <Container>
                <Header />
                <PortalChooser />
              </Container>
            </div>
            <div className='break'><div></div></div>
            <div className='basic__layout section-1'>
              <Models />
            </div>
          </Fragment>
        )}

        <div className='basic__layout section-2'>
          <Container>
            <SelectedConfiguration />
          </Container>
        </div>
        <div className='basic__layout section-3'>
          <Container>
            <Form />
          </Container>
        </div>
        <div className='basic__layout section-4'>
          <Container>
            <Footer />
          </Container>
        </div>
      </main>
    )
  }
}

export default withRouter(Home)