import React, { Component, Fragment } from 'react'
import Loader from 'react-loader-spinner'

class Image extends Component {
  state = {
    loading: true
  }

  componentWillReceiveProps(nextProps) {
    const { src } = nextProps

    if(src !== this.props.src) {
      this.setState({ loading: true })
    }
  }

  handleLoading = () => {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 250)
    
  }
  
  render() {
    const { loading } = this.state
    const { src, ...rest } = this.props
    return (
      <Fragment>
        <img
          onLoad={this.handleLoading}
          onError={this.handleLoading}
          src={src} alt=''
          {...rest}
        />
        {loading && (
          <div className='image__loader'>
            <Loader
              type="Oval"
              color="#218838"
              height={100}
              width={100}
            />
          </div>
        )}
      </Fragment>
    )
  }
}
 
export default Image