import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'

class SingleVariant extends Component {
  state = {
    link: '/-/-/-/-/-',
    status: ''
  }

  componentDidMount() {
    const { activeItem, variant } = this.props
    this.renderLink()

    if (variant.id === activeItem) {
      this.setState({ status: 'active' })
    } else {
      this.setState({ status: '' })
    }
  }

  componentDidUpdate (prevProps) {
    const { activeItem, variant } = this.props
    const { params } = this.props.match
    if (JSON.stringify(params) !== JSON.stringify(prevProps.match.params)) {
      this.renderLink()

      if (variant.id === activeItem) {
        this.setState({ status: 'active' })
      } else {
        this.setState({ status: '' })
      }
    }
  }

  renderLink = () => {
    const { top, left, right, door } = this.props.match.params
    const { type, variant, modelType } = this.props

    switch(type) {
      case 'top': this.setState({ link: `/${modelType}/${variant.id}/${left ? left : '-'}/${right ? right : '-'}/${door ? door : '-'}` })
        break
      case 'left': this.setState({ link: `/${modelType}/${top ? top : '-'}/${variant.id}/${right ? right : '-'}/${door ? door : '-'}` })
        break
      case 'right': this.setState({ link: `/${modelType}/${top ? top : '-'}/${left ? left : '-'}/${variant.id}/${door ? door : '-'}` })
        break
      case 'door': this.setState({ link: `/${modelType}/${top ? top : '-'}/${left ? left : '-'}/${right ? right : '-'}/${variant.id}` })
        break
      default: this.setState({ link: '/-/-/-/-/-' })
    }
  }

  render() {
    const { link, status } = this.state
    const { variant } = this.props
    return (
      <Link to={link}>
        <div className={`variant__container ${status}`}>
          <div
            onClick={this.handleClick}
            className='variant'
            style={variant && {backgroundColor: variant.pickerColor}}
          >

          </div>
        </div>
        
      </Link>      
    )
  }
}
 
export default withRouter(SingleVariant)
