import React, { Component } from 'react'
import { ColorPicker, Visualisation } from './components'
import { Container, Row, Col } from 'reactstrap'

class PortalChooser extends Component {
  render() { 
    return (
      <Container>
        <Row>
          <Col className='color-picker' xs="12" md="7">
            <ColorPicker />
          </Col>
          <Col className='visualisation' xs="12" md="5">
            <Visualisation />
          </Col>
        </Row>        
      </Container>
    )
  }
}
 
export default PortalChooser

