import {
  MODELS_LIST_SUCCESS,
  MODELS_LIST_FAILED,
  GET_BLANK_IMAGES
} from './actions'

let initialState = {
  modelsList: {
    door: [],
    left: [],
    right: [],
    top: []
  },
  blankImages: [],
  modelsLoaded: false,
  modelsFailed: false
}

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case MODELS_LIST_SUCCESS:
      return {
        ...state,
        modelsList: action.payload,
        modelsLoaded: action.modelsLoaded
      }

    case MODELS_LIST_FAILED:
      return {
        ...state,
        modelsFailed: action.modelsFailed
      }
    case GET_BLANK_IMAGES:
      return {
        ...state,
        blankImages: action.blankImages
      }
    default:
      return state
  }
}