import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { Left, Top, Right, Door } from './components'
import { withRouter } from 'react-router-dom'

class ColorPicker extends Component {
  state = {
    isMobile: false,
    isButtonClicked: false
  }

  componentDidMount() {
    this.handleWindowWidth()
    window.addEventListener('resize', this.handleWindowWidth)
  }

  handleWindowWidth = () => {
    if(window.innerWidth <= 768) {
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }

  handleToggleClick = () => {
    this.setState({ isButtonClicked: !this.state.isButtonClicked })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowWidth)
  }

  render() {
    const { isMobile, isButtonClicked } = this.state
    const {
      door,
      left,
      right,
      top,
      match: { params: { type } }
    } = this.props

    if(!type) {
      return (
        <div className='variant__container no-models'>
          <h2 className='no-models'>Wybierz Wariant</h2>
        </div>
      )
    }

    return (
      <div className='variant__container'>
        {
          isMobile && <Button className='select__configuration_btn' onClick={this.handleToggleClick}><span>Wybierz konfigurację</span></Button>
        }
        <div className={isMobile && isButtonClicked ? 'picker__active' : 'picker__unactive'}>
          <Left models={left} />
          <Top models={top} />
          <Right models={right} />
          <Door models={door} />

          {isMobile && isButtonClicked &&
          <Button onClick={this.handleToggleClick} type="button" className='select__configuration_btn'>
            <span aria-hidden="true">Wroć</span>
          </Button>
          }
        </div>
        
      </div>
    )
  }
}

export default withRouter(ColorPicker)

