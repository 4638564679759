export const MODELS_LIST_SUCCESS = 'MODELS_LIST_SUCCESS'
export const MODELS_LIST_FAILED = 'MODELS_LIST_FAILED'
export const GET_BLANK_IMAGES = 'GET_BLANK_IMAGES'

export const loadModelsList = (payload) => {
  return {
    type: MODELS_LIST_SUCCESS,
    payload,
    modelsLoaded: true
  }
}

export const modelsListFailed = () => {
  return {
    type: MODELS_LIST_FAILED,
    modelsFailed: true
  }
}

export const getBlankImages = (blankImages) => {
  return {
    type: GET_BLANK_IMAGES,
    blankImages
  }
}

