import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'

const initialState =
  typeof window !== 'undefined' && window && window.__INITIAL_STATE__


export const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(thunk)
)