import React, { Component } from 'react'
import { Router } from '@router'
import 'normalize.css'
import '../../styles/index.scss'

class App extends Component {
  render() {
    return (
      <Router />
    )
  } 
}

export default App
