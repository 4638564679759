import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '@components/home/components/models'
import App from '../components/App'

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...actions
  },
  dispatch
)

const mapStateToProps = (state) => {
  const { models } = state
  return {
    models
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)