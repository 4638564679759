import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../actions'
import Models from '../components/Models'

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...actions
  },
  dispatch
)

export default connect(null, mapDispatchToProps)(Models)