import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink } from 'reactstrap'
import Switch from './Switch'

class Header extends Component {
  state = {
    open: false
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    return (
      <Fragment>
        <Navbar dark className='header' color="transparent" light expand="md">
          <Link to='/'>
            <NavbarBrand className='brand'>PortaleDrzwiowe.pl</NavbarBrand>
          </Link>
          <NavbarToggler onClick={this.toggle}/>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink href='https://kreatywniewdrewnie.pl/realizacje/' target='blank'>PORTFOLIO</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='https://kreatywniewdrewnie.pl/kontakt/' target='blank'>KONTAKT</NavLink>
              </NavItem>
              <NavItem>
                <Switch /> 
              </NavItem> 
            </Nav>
          </Collapse>
        </Navbar>
      </Fragment>
      
    )
  }
}

export default Header