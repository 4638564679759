import React, { Component, Fragment } from 'react'
import { Picker } from '@components/shared'
import { withRouter } from 'react-router-dom'

class Top extends Component {
  render() { 
    const {
      models,
      match: {
        params: {
          top: activeItem,
          type: modelType
        }
      }
    } = this.props
    return (
      <Fragment>
        <h6 className='part__name'>Góra</h6>
        <Picker
          colorVariants={models}
          type='top'
          activeItem={activeItem}
          modelType={modelType}
        />
      </Fragment>
    )
  }
}
 
export default withRouter(Top)