export const scrollTo = (element, offset = 0) => {
  const { top: elementRect } = element.getBoundingClientRect()          
  const bodyRect = document.body.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  })
}