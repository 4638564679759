import React, { Component } from 'react'
import { Input as BootstrapInput, Label, FormGroup } from 'reactstrap'

export class Input extends Component {
  state = {
    active: false
  }

  handleFocus = () => {
    this.setState({ active: true })
  }

  handleBlur = () => {
    const { onBlur, value } = this.props
    onBlur()
    if (!value) {
      this.setState({ active: false })
    }
  }

  render() {
    const {
      name,
      label,
      onChange,
      error,
      touched,
      value
    } = this.props
    const { active } = this.state
    return(
      <FormGroup className='input__wrapper'>
        <Label for={name} className={`input__label ${active ? 'active' : ''}`}>{label}</Label> 
        <BootstrapInput
          id={name}
          className='form__input'
          onFocus={this.handleFocus}
          onChange={onChange}
          onBlur={this.handleBlur}
          name={name}
          invalid={error && touched}
          valid={!error && touched}
          value={value}
        />
      </FormGroup>
    )
  }
}